<template>
    <div class="pr--asterisks" />
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockAsterisks',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
