<template>
    <div class="pr--editor--file">
        <div class="--head">
            <div :class="[`--icon --extension-${data.file.extension}`]">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="40"
                ><path d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z" /></svg>
            </div>
            <div>
                <div
                    class="--title"
                    v-html="data.title"
                />
                <div class="--size">
                    {{ niceBytes(data.file.size) }}
                </div>
            </div>
        </div>
        <a
            class="btn btn-light btn-icon --download"
            :href="data.file.url"
            target="_blank"
            rel="nofollow noindex noreferrer"
        >
            <font-awesome-icon icon="download" />
        </a>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockAttaches',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    },
    methods: {
        niceBytes(x) {
            const units = ['байт', 'Кб', 'Мб', 'Гб', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            let l = 0;
            let n = parseInt(x, 10) || 0;
            while (n >= 1024 && ++l) {
                n = n / 1024;
            }
            return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        }
    }
});
</script>
