import { defineStore, acceptHMRUpdate } from 'pinia';
// import useAppCookies from '@/hooks/cookies';

interface State {
    data: {
        blocks: [],
        time: number,
        version: string,
    },
    preview: any,
    focusedId: string|null
    focusedIndex: number|null
}
export const useStoreAppEditorBase = defineStore({
    id: 'app/editor/base',

    state: (): State => ({
        data: {
            time: 1626251992170,
            blocks: [],
            version: '2.22.1',
        },
        preview: {
            enabled: false,
            type: 'default',
        },
        focusedId: null,
        focusedIndex: null,
    }),
    actions: {
        setBlocks(blocks: []) {
            this.data.blocks = blocks;
        },
        setFocusedIndex(index: number) {
            this.focusedIndex = index;
        },
        setFocusedId(id: string|null) {
            this.focusedId = id;
        },
        setPreviewEnabled(val: boolean, cookies = false) {
            if (cookies) {
                // const { setEditorPreviewEnabled } = useAppCookies();
                // setEditorPreviewEnabled(val);
            }
            this.preview.enabled = val;
        },
        setPreviewType(val: string, cookies = false) {
            if (cookies) {
                // const { setEditorPreviewType } = useAppCookies();
                // setEditorPreviewType(val);
            }
            this.preview.type = val;
        },
    }
});
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoreAppEditorBase, import.meta.hot));
}
