<template>
    <div class="pr--editor--audio-file">
        <div v-if="data.file !== undefined && data.file !== null && data.file.url !== undefined && data.file.url !== null" class="--info">
            <div class="--head-file">
                <div class="--title" v-html="data.title" />
                <!--                <div class="--size">-->
                <!--                    {{ niceBytes(data.file.size) }}-->
                <!--                </div>-->
            </div>
        </div>
        <div v-if="data.file !== undefined && data.file !== null && data.file.url !== undefined && data.file.url !== null" class="--player">
            <VuePlyr :ref="`plyr-${id}`">
                <audio
                    :ref="`player-${id}`"
                    controls
                    data-plyr-config="['play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'pip', 'airplay']"
                    crossorigin
                    playsinline
                >
                    <source
                        :src="data.file.url"
                        type="audio/mp3"
                    >
                </audio>
            </VuePlyr>
        </div>
        <div id="waveform" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
//import VuePlyr from 'vue-plyr';
// import WaveSurfer from 'wavesurfer.js';
import { useStoreAppPlayerIndex } from '@/stores/app/player';

export default defineComponent({
    name: 'EditorBlockAudio',
    components: {
        //VuePlyr,
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        },
        id: {
            type: [String],
            default: () => ''
        }
    },
    setup() {
        const player = useStoreAppPlayerIndex();
        return {
            player,
        };
    },
    data() {
        return {
            isMounted: false,
        };
    },
    watch: {
        // 'data.file.url'() {
        //     const self = this;
        //     setTimeout(function () {
        //         self.$refs[`player-${self.id}`].load();
        //         self.onLoad();
        //     }, 300);
        // },
        'player.active'() {
            const self = this;
            if (self.player.active === null) {
                return;
            }
            //console.log(self.player.active);
            if (self.$refs[`plyr-${self.id}`] !== undefined) {
                if (self.player.active !== self.id) {
                    self.$refs[`plyr-${self.id}`].player.pause();
                } else {
                    self.$refs[`plyr-${self.id}`].player.play();
                }
            }
        }
    },
    mounted() {
        const self = this;
        self.isMounted = true;
        if (self.data.file !== undefined && self.data.file !== null && self.data.file.url !== undefined && self.data.file.url !== null) {
            self.onLoad();
            // const wavesurfer = WaveSurfer.create({
            //     container: '#waveform',
            //     scrollParent: true
            // });
            // wavesurfer.load(self.data.file.url);
        }
    },
    methods: {
        //...mapActions('app/player', ['pushPlayers', 'setActive']),
        onPlay() {
            console.log('play', this.id);
            this.player.setActive(this.id);
        },
        onPause() {
            console.log('pause', this.id);
            //this.player.setActive(null);
        },
        onLoad() {
            const self = this;
            setTimeout(function () {
                self.player.pushPlayers(self.id);
                if (self.$refs[`plyr-${self.id}`] !== undefined) {
                    self.$refs[`plyr-${self.id}`].player.on('play', () => self.onPlay());
                    self.$refs[`plyr-${self.id}`].player.on('pause', () => self.onPause());
                }
            }, 500);
        }
    }
});
</script>
