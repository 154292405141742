<template>
    <div class="pr--editor--list-nested__item-body">
        <div
            class="pr--editor--list-nested__item-content"
            v-html="item.content"
        />
        <ul
            v-if="item.items !== undefined && item.items.length && type === 'unordered'"
            class="pr--editor--list-nested pr--editor--list-nested--unordered pr--editor--list-nested__item-children"
        >
            <li
                v-for="(item1, key1) in item.items"
                :key="key1"
                class="pr--editor--list-nested__item"
            >
                <EditorBlockListItem
                    :item="item1"
                    :type="type"
                />
            </li>
        </ul>
        <ol
            v-if="item.items !== undefined && item.items.length && type === 'ordered'"
            class="pr--editor--list-nested pr--editor--list-nested--ordered pr--editor--list-nested__item-children"
        >
            <li
                v-for="(item1, key1) in item.items"
                :key="key1"
                class="pr--editor--list-nested__item"
            >
                <EditorBlockListItem
                    :item="item1"
                    :type="type"
                />
            </li>
        </ol>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockListItem',
    components: {
    },
    props: {
        item: {
            type: [Object],
            default: () => {}
        },
        type: {
            type: [String],
            default: () => ''
        }
    }
});
</script>
