<template>
    <div v-if="data.length">
        <!--        <v-gallery :images="images" :index="index" @close="index = null"></v-gallery>-->
        <!--        <div-->
        <!--            class="image"-->
        <!--            v-for="(image, imageIndex) in images"-->
        <!--            :key="imageIndex"-->
        <!--            @click="index = imageIndex"-->
        <!--            :style="{ backgroundImage: 'url(' + image + ')' }"-->
        <!--        ></div>-->

        <div class="pr--gallery fancybox-gallery">
            <a
                v-for="(image, imageIndex) in images"
                :key="imageIndex"
                :href="image"
                :data-caption="data[imageIndex].caption"
                class="pr--gallery__item"
            >
                <img
                    :src="image"
                    width="100%"
                    :alt="data[imageIndex].caption"
                    class="pr--gallery__img"
                >
            </a>
        </div>
    </div>
</template>

<script>

import { Fancybox } from '@fancyapps/ui/dist/fancybox.esm';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockCarousel',
    components: {
    },
    props: {
        data: {
            type: [Array],
            default: () => {}
        }
    },
    data() {
        return {
            // images: [
            //     'https://dummyimage.com/400/000000/ffffff',
            // ],
            index: null,
            settings: {
                centerMode: true,
                centerPadding: '20px',
                focusOnSelect: true,
                infinite: true,
                slidesToShow: 3,
                speed: 500
            }
        };
    },
    computed: {
        images() {
            const self = this;
            const images = [];
            self.data.forEach(function (image) {
                images.push(image.url);
            });
            return images;
        },
    },
    mounted() {
        Fancybox.bind('.fancybox-gallery a', {
            on: {
                ready: (fancybox) => {
                    //console.log(`fancybox #${fancybox.id} is ready!`);
                }
            }
        });
    },
    methods: {
        next() {
            this.$refs.flickity.next();
        },

        previous() {
            this.$refs.flickity.previous();
        }
    }
});
</script>
