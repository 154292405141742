<template>
    <div class="pr--editor--link">
        <a
            v-if="data.meta.image !== undefined"
            class="--link"
            target="_blank"
            rel="nofollow noindex noreferrer"
            :href="data.link"
            title="Перейти"
        >
            <div
                class="--image"
                :style="{
                    backgroundImage: `url(${data.meta.image.url})`
                }"
            />
            <div class="--title">{{ data.meta.title }}</div>
            <p class="--description">
                {{ data.meta.description }}
            </p>
            <span class="--anchor">{{ data.link }}</span>
        </a>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorLinkTool',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
