<template>
    <div class="pr--editor--embed-iframe">
        <div class="--player">
            <VuePlyr
                :ref="`plyr-${id}`"
                :options="{
                    controls: [
                        'play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen',
                    ],
                    settings: [
                        'captions', 'quality', 'speed', 'loop',
                    ],
                }"
            >
                <div
                    data-plyr-provider="youtube"
                    :data-plyr-embed-id="data.embed"
                />
            </VuePlyr>
        </div>
        <!--        <iframe-->
        <!--            style="width:100%;"-->
        <!--            height="320"-->
        <!--            frameborder="0"-->
        <!--            allowfullscreen=""-->
        <!--            :src="data.embed"-->
        <!--            class="embed-tool__content youtube__iframe"-->
        <!--        />-->
        <a
            :href="data.source"
            class="--youtube-link"
            target="_blank"
        >
            Смотреть на YouTube
        </a>
        <div
            v-if="data.caption !== ''"
            class="text-center mt-3"
            v-html="data.caption"
        />
    </div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';
import { defineComponent } from 'vue';
//import VuePlyr from 'vue-plyr';
import { useStoreAppPlayerIndex } from '@/stores/app/player';

export default defineComponent({
    name: 'EditorBlockEmbed',
    components: {
        //VuePlyr,
        // VuePlyr: () => import('vue-plyr'),
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        },
        id: {
            type: [String],
            default: () => ''
        }
    },
    setup() {
        const player = useStoreAppPlayerIndex();
        return {
            player,
        };
    },
    // computed: mapState({
    //     // player: (state) => state.app.player,
    // }),
    watch: {
        // 'data.embed'() {
        //     const self = this;
        //     if (self.data.embed !== null) {
        //         setTimeout(function () {
        //             self.$refs[`player-${self.id}`].load();
        //             self.onLoad();
        //         }, 300);
        //     }
        // },
        'player.active'() {
            const self = this;
            if (self.player.active === null) {
                return;
            }
            if (self.$refs[`plyr-${self.id}`] !== undefined) {
                if (self.player.active !== self.id) {
                    self.$refs[`plyr-${self.id}`].player.pause();
                } else {
                    self.$refs[`plyr-${self.id}`].player.play();
                }
            }
        }
    },
    mounted() {
        const self = this;
        self.isMounted = true;
        self.onLoad();
    },
    methods: {
        // ...mapActions('app/player', ['pushPlayers', 'setActive']),
        onPlay() {
            this.player.setActive(this.id);
        },
        onPause() {
            console.log('pause', this.id);
            //this.player.setActive(null);
        },
        onLoad() {
            const self = this;
            setTimeout(function () {
                self.player.pushPlayers(self.id);
                if (self.$refs[`plyr-${self.id}`] !== undefined) {
                    self.$refs[`plyr-${self.id}`].player.on('play', () => self.onPlay());
                    self.$refs[`plyr-${self.id}`].player.on('pause', () => self.onPause());
                }
            }, 500);
        }
    }
});
</script>
