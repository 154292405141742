<template>
    <p
        :class="[`text-${data.alignment}`]"
        v-html="data.text"
    />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockParagraph',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
