<template>
    <p :class="`text-${data.alignment}`">
        Опубликовано: {{ momentDate.latest(data.text) }}
    </p>
</template>

<script>
import { defineComponent } from 'vue';
import useMomentDate from '@/hooks/momentDate';

export default defineComponent({
    name: 'EditorBlockDate',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    },
    setup() {
        const momentDate = useMomentDate();
        return {
            momentDate,
        };
    }
});
</script>
