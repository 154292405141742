<template>
    <div class="pr--warning">
        <div
            v-if="data.title !== ''"
            class="--head"
        >
            <b
                class="mb-0"
                v-html="data.title"
            />
        </div>
        <div v-if="data.message !== ''">
            <p
                class="mb-0"
                v-html="data.message"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockWarning',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
