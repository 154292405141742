<template>
    <div class="--rubric">
        {{ data.text }}
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockRubric',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        },
    }
});
</script>
