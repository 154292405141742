<template>
    <div v-if="data !== undefined">
        <table class="table table-bordered table-fixed">
            <tbody>
                <tr
                    v-for="(row, key) in data.content.keys()"
                    :key="key"
                >
                    <td
                        v-for="(value, keyV) in data.content[row]"
                        :key="keyV"
                        v-html="value"
                    />
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockTable',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
