<template>
    <div
        :class="[`alert alert-${data.type}`]"
        role="alert"
        v-html="data.message"
    />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockAlert',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
