<template>
    <div
        v-if="item[prepend.key] !== undefined && item[prepend.key] !== null"
        :id="`editor-active-prepend-${prepend.key}`"
        :key="`prepend-${prepend.id}`"
        :class="`pr--editor--block pr--editor--${prepend.type} ${checkFocused && editor.focusedId === 'prepend-' + prepend.key ? 'active' : ''}`"
    >
        <EditorBlockHeaderH1
            v-if="prepend.type === 'header-h1'"
            :data="{ text: item[prepend.key] }"
        />
        <!--            <EditorHeaderRubric-->
        <!--                v-if="block.type === 'rubric' && block.data.text !== null"-->
        <!--                :data="block.data"-->
        <!--            />-->
        <EditorBlockParagraph
            v-if="prepend.type === 'description'"
            :data="{ text: item[prepend.key], alignment: prepend.alignment ?? 'left' }"
        />
        <EditorBlockDate
            v-if="prepend.type === 'date'"
            :data="{ text: item[prepend.key], alignment: prepend.alignment ?? 'left' }"
        />
        <EditorBlockRubric
            v-if="prepend.type === 'rubric'"
            :data="{ text: item[prepend.key] }"
        />
        <EditorBlockWarning
            v-if="prepend.type === 'warning'"
            :data="{ title: '', message: item[prepend.key] }"
        />
        <slot name="before-image" />
        <EditorBlockImage
            v-if="prepend.type === 'image'"
            :data="{ file: { url: item[prepend.key] }, withBackground: false, stretched: true, caption: null, source: null }"
        />
    </div>
</template>

<script>

import { defineComponent } from 'vue';
import EditorBlockHeaderH1 from './../Block/EditorBlockHeaderH1.vue';
import EditorBlockImage from './../Block/EditorBlockImage.vue';
import EditorBlockParagraph from './../Block/EditorBlockParagraph.vue';
import EditorBlockDate from './../Block/EditorBlockDate.vue';
import EditorBlockRubric from './../Block/EditorBlockRubric.vue';
import EditorBlockWarning from './../Block/EditorBlockWarning.vue';
import { useStoreAppEditorBase } from '@/stores/app/editor/base';

export default defineComponent({
    name: 'EditorPretendShow',
    components: {
        EditorBlockImage,
        EditorBlockParagraph,
        EditorBlockDate,
        EditorBlockHeaderH1,
        EditorBlockRubric,
        EditorBlockWarning,
    },
    props: {
        prepend: {
            type: [Object],
            default: () => {},
        },
        item: {
            type: [Object],
            default: () => null,
        },
        checkFocused: {
            type: [Boolean],
            default: () => true,
        }
    },
    setup() {
        const editor = useStoreAppEditorBase();
        //const store = useStoreAppEditorItemStore();
        return {
            editor,
            //store,
        };
    },
});
</script>
