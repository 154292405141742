<template>
    <div class="pr--editor ">
        <EditorPretendShow v-for="(prepend, key) in prepends" :key="key" :prepend="prepend" :item="item" />
        <slot name="before-text" />
        <div
            v-for="(block, key) in blocks"
            :id="`editor-active-${block.id}`"
            :key="key"
            :class="`pr--editor--block pr--editor--${block.type} ${checkFocused && editor.focusedId === block.id ? 'active' : ''}`"
        >
            <EditorBlockParagraph
                v-if="block.type === 'paragraph'"
                :data="block.data"
            />
            <EditorBlockAlert
                v-if="block.type === 'alert'"
                :data="block.data"
            />
            <EditorBlockHeader
                v-if="block.type === 'header'"
                :data="block.data"
                :tunes="block.tunes"
            />
            <EditorBlockLinkTool
                v-if="block.type === 'linkTool'"
                :data="block.data"
            />
            <EditorBlockDelimiter
                v-if="block.type === 'delimiter'"
                :data="block.data"
            />
            <EditorBlockAsterisks
                v-if="block.type === 'asterisks'"
                :data="block.data"
            />
            <EditorBlockImage
                v-if="block.type === 'image'"
                :data="block.data"
            />
            <EditorBlockTable
                v-if="block.type === 'table'"
                :data="block.data"
            />
            <EditorBlockList
                v-if="block.type === 'list'"
                :data="block.data"
            />
            <EditorBlockQuote
                v-if="block.type === 'quote'"
                :data="block.data"
            />
            <EditorBlockCode
                v-if="block.type === 'code'"
                :data="block.data"
            />
            <!--            <EditorAnyButton-->
            <!--                v-if="block.type === 'AnyButton'"-->
            <!--                :data="block.data"-->
            <!--            />-->
            <EditorBlockWarning
                v-if="block.type === 'warning'"
                :data="block.data"
            />
            <EditorBlockAttaches
                v-if="block.type === 'attaches'"
                :data="block.data"
            />
            <EditorBlockAudio
                v-if="block.type === 'audio'"
                :id="block.id"
                :data="block.data"
            />
            <EditorBlockVideo
                v-if="block.type === 'video'"
                :id="block.id"
                :data="block.data"
            />
            <EditorBlockCarousel
                v-if="block.type === 'carousel'"
                :id="block.id"
                :data="block.data"
            />
            <EditorBlockEmbed
                v-if="block.type === 'embed'"
                :id="block.id"
                :data="block.data"
            />
            <EditorBlockPerson
                v-if="block.type === 'person'"
                :data="block.data"
            />
            <EditorBlockIncut
                v-if="block.type === 'incut'"
                :data="block.data"
            />
        </div>
        <!--        <EditorCustomBottom :article="article" />-->
    </div>
</template>

<script>

import EditorBlockAlert from '@/../../editorjs/components/Block/EditorBlockAlert.vue';
import EditorBlockAsterisks from '@/../../editorjs/components/Block/EditorBlockAsterisks.vue';
import EditorBlockAttaches from '@/../../editorjs/components/Block/EditorBlockAttaches.vue';
import EditorBlockAudio from '@/../../editorjs/components/Block/EditorBlockAudio.vue';
import EditorBlockCarousel from '@/../../editorjs/components/Block/EditorBlockCarousel.vue';
import EditorBlockCode from '@/../../editorjs/components/Block/EditorBlockCode.vue';
import EditorBlockDelimiter from '@/../../editorjs/components/Block/EditorBlockDelimiter.vue';
import EditorBlockEmbed from '@/../../editorjs/components/Block/EditorBlockEmbed.vue';
import EditorBlockHeader from '@/../../editorjs/components/Block/EditorBlockHeader.vue';
import EditorBlockImage from '@/../../editorjs/components/Block/EditorBlockImage.vue';
import EditorBlockIncut from '@/../../editorjs/components/Block/EditorBlockIncut.vue';
import EditorBlockLinkTool from '@/../../editorjs/components/Block/EditorBlockLinkTool.vue';
import EditorBlockList from '@/../../editorjs/components/Block/EditorBlockList.vue';
import EditorBlockParagraph from '@/../../editorjs/components/Block/EditorBlockParagraph.vue';
import EditorBlockPerson from '@/../../editorjs/components/Block/EditorBlockPerson.vue';
import EditorBlockQuote from '@/../../editorjs/components/Block/EditorBlockQuote.vue';
import EditorBlockTable from '@/../../editorjs/components/Block/EditorBlockTable.vue';
import EditorBlockVideo from '@/../../editorjs/components/Block/EditorBlockVideo.vue';
import EditorBlockWarning from '@/../../editorjs/components/Block/EditorBlockWarning.vue';
import EditorPretendShow from '@/../../editorjs/components/Pretend/EditorPretendShow.vue';
import { defineComponent, ref, watch, toRaw, watchEffect, inject, defineAsyncComponent } from 'vue';
import { useStoreAppEditorBase } from '@/stores/app/editor/base';

export default defineComponent({
    name: 'EditorPreviewDefault',
    components: {
        EditorBlockCarousel,
        EditorPretendShow,
        EditorBlockParagraph,
        EditorBlockWarning,
        EditorBlockImage,
        EditorBlockDelimiter,
        EditorBlockQuote,
        EditorBlockCode,
        EditorBlockAttaches,
        EditorBlockEmbed,
        EditorBlockList,
        EditorBlockAsterisks,
        EditorBlockIncut,
        EditorBlockLinkTool,
        EditorBlockAlert,
        EditorBlockPerson,
        EditorBlockHeader,
        EditorBlockVideo,
        EditorBlockAudio,
        EditorBlockTable,
    },
    props: {
        prepend: {
            type: [Array],
            default: () => [],
        },
        prepends: {
            type: [Array],
            default: () => [],
        },
        blocks: {
            type: [Array],
            default: () => [],
        },
        isPreview: {
            type: [Boolean],
            default: () => false,
        },
        item: {
            type: [Object],
            default: () => null,
        },
        checkFocused: {
            type: [Boolean],
            default: () => false,
        }
    },
    setup() {
        const editor = useStoreAppEditorBase();
        //const smoothScroll = inject('smoothScroll');

        watch(() => editor.focusedId, () => {
            // const el = document.querySelector(`#editor-active-${editor.focusedId}`);
            // if (el !== null) {
            //     //console.log('focusedId', editor.focusedId, `#editor-active-${editor.focusedId}`, el);
            //     smoothScroll({
            //         duration: 300,
            //         offset: -108 - 54, // 54 header + 54 header + 54 просто отступ
            //         scrollTo: el,
            //         container: document.querySelector('.pr--main--center.__right'),
            //         updateHistory: false,
            //     });
            // }
        });
        return {
            editor,
        };
    },
});
</script>
