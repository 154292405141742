<template>
    <div
        :class="[
            'pr--editor--image-person',
            data.withBackground ? '__background' : ''
        ]"
    >
        <img
            :src="data.file.url"
            :alt="data.caption || 'Preview image'"
            :class="[
                data.stretched ? 'w-100' : 'w-50',
                data.withBackground ? '__background' : '',
            ]"
        >
        <div
            v-if="data.captionHead !== ''"
            class="--head"
            v-html="data.captionHead"
        />
        <div
            v-if="data.caption !== ''"
            class="--text"
            v-html="data.caption"
        />
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockPerson',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
