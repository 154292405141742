<template>
    <div
        v-if="data.file.url !== undefined && data.file.url !== null"
        :class="[
            'pr--editor--image fancybox-gallery',
            data.withBackground ? '__background' : '',
            data.stretched ? '__stretched' : '',
        ]"
    >
        <a
            :href="data.file.url"
            :data-caption="data.caption"
            data-fancybox="article"
            :class="[
                data.stretched ? 'w-100' : 'w-50',
            ]"
        >
            <img
                :src="data.file.url"
                :alt="data.caption || 'Preview image'"
                :class="[
                    'w-100',
                    data.withBackground ? '__background' : '',
                ]"
            >
        </a>
        <div v-if="data.caption !== null && data.caption !== ''" class="--text" v-html="data.caption" />
        <div v-if="data.source !== undefined && data.source !== null && data.source !== '' && data.source.includes('http')" class="--source">
            Источник: <a :href="data.source">{{ data.source }}</a>
        </div>
        <div v-if="data.source !== undefined && data.source !== null && data.source !== '' && !data.source.includes('http')" class="--source" v-html="data.source" />
    </div>
</template>

<script>

import { Fancybox } from '@fancyapps/ui/dist/fancybox.esm';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockImage',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    },
    mounted() {
        Fancybox.bind('.fancybox-gallery a', {
            on: {
                ready: (fancybox) => {
                    //console.log(`fancybox #${fancybox.id} is ready!`);
                }
            }
        });
    },
});
</script>
