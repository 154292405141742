<template>
    <div
        :class="[
            data.anchor !== null ? 'pr--editor--header--with-anchor' : '',
            data.anchor !== null ? `__level-${data.level}` : '',
        ]"
    >
        <h1
            v-if="data.level === 1"
            :class="[`text-${data.alignment}`]"
            v-html="data.text"
        />
        <h2
            v-if="data.level === 2"
            :class="[`text-${data.alignment}`]"
            v-html="data.text"
        />
        <h3
            v-if="data.level === 3"
            :class="[`text-${data.alignment}`]"
            v-html="data.text"
        />
        <h4
            v-if="data.level === 4"
            :class="[`text-${data.alignment}`]"
            v-html="data.text"
        />
        <h5
            v-if="data.level === 5"
            :class="[`text-${data.alignment}`]"
            v-html="data.text"
        />
        <h6
            v-if="data.level === 6"
            :class="[`text-${data.alignment}`]"
            v-html="data.text"
        />
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockHeader',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        },
        tunes: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
