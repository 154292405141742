import { defineStore, acceptHMRUpdate } from 'pinia';

interface State {
    active: string|null
    players: []
}

export const useStoreAppPlayerIndex = defineStore({
    id: 'app/player/index',

    state: (): State => ({
        active: null,
        players: [],
    }),

    actions: {
        setActive(name: string) {
            this.active = name;
        },
        pushPlayers(name: string) {
            if (!this.players.includes(name)) {
                this.players.push(name);
            }
        },
        clearPlayers() {
            this.players = [];
        }
    }
});
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoreAppPlayerIndex, import.meta.hot));
}
