<template>
    <div>
        <ul
            v-if="data.style === 'unordered'"
            class="pr--editor--list-nested pr--editor--list-nested--unordered pr--editor--list-nested-main"
        >
            <li
                v-for="(item, key) in data.items"
                :key="key"
                class="pr--editor--list-nested__item"
            >
                <EditorBlockListItem
                    :item="item"
                    :type="data.style"
                />
            </li>
        </ul>
        <ol
            v-if="data.style === 'ordered'"
            class="pr--editor--list-nested pr--editor--list-nested--ordered pr--editor--list-nested-main"
        >
            <li
                v-for="(item, key) in data.items"
                :key="key"
                class="pr--editor--list-nested__item"
            >
                <EditorBlockListItem
                    :item="item"
                    :type="data.style"
                />
            </li>
        </ol>
    </div>
</template>

<script>

import { defineComponent } from 'vue';
import EditorBlockListItem from './EditorBlockListItem.vue';

export default defineComponent({
    name: 'EditorBlockList',
    components: {
        EditorBlockListItem,
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
