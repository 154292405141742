<template>
    <div v-if="isMounted" class="pr--editor--video-file w-100 m-0">
        <div v-if="data.file !== undefined && data.file !== null && data.file.url !== undefined && data.file.url !== null" class="--player">
            <VuePlyr :ref="`plyr-${id}`">
                <video
                    :ref="`player-${id}`"
                    controls
                    crossorigin
                    playsinline
                    style="width: 100%;"
                >
                    <source
                        size="720"
                        :src="data.file.url"
                        type="video/mp4"
                    >
                </video>
            </VuePlyr>
        </div>
        <EditorBlockEmbed v-if="data.embed !== null" :id="id" :data="data.embed" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
//import VuePlyr from 'vue-plyr';
import { useStoreAppPlayerIndex } from '@/stores/app/player';
import EditorBlockEmbed from './EditorBlockEmbed.vue';

export default defineComponent({
    name: 'EditorBlockVideo',
    components: {
        //VuePlyr,
        EditorBlockEmbed,
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        },
        id: {
            type: [String],
            default: () => ''
        }
    },
    setup() {
        const player = useStoreAppPlayerIndex();
        return {
            player,
        };
    },
    data() {
        return {
            isMounted: false,
        };
    },
    // computed: mapState({
    //     player: state => state.app.player,
    // }),
    watch: {
        // 'data.file.url'() {
        //     const self = this;
        //     if (self.data.file !== undefined && self.data.file !== null && self.data.file.url !== undefined && self.data.file.url !== null) {
        //         setTimeout(function () {
        //             self.$refs[`player-${self.id}`].load();
        //             self.onLoad();
        //         }, 300);
        //     }
        // },
        'player.active'() {
            const self = this;
            if (self.player.active === null) {
                return;
            }
            //console.log(self.player.active);
            if (self.$refs[`plyr-${self.id}`] !== undefined) {
                if (self.player.active !== self.id) {
                    self.$refs[`plyr-${self.id}`].player.pause();
                } else {
                    self.$refs[`plyr-${self.id}`].player.play();
                }
            }
        }
    },
    mounted() {
        const self = this;
        self.isMounted = true;
        if (self.data.file !== undefined && self.data.file !== null && self.data.file.url !== undefined && self.data.file.url !== null) {
            self.onLoad();
        }
    },
    methods: {
        //...mapActions('app/player', ['pushPlayers', 'setActive']),
        onPlay() {
            console.log('play', this.id);
            this.player.setActive(this.id);
        },
        onPause() {
            console.log('pause', this.id);
            //this.player.setActive(null);
        },
        onLoad() {
            const self = this;
            setTimeout(function () {
                self.player.pushPlayers(self.id);
                if (self.$refs[`plyr-${self.id}`] !== undefined) {
                    self.$refs[`plyr-${self.id}`].player.on('play', () => self.onPlay());
                    self.$refs[`plyr-${self.id}`].player.on('pause', () => self.onPause());
                }
            }, 500);
        }
    }
});
</script>
