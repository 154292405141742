<template>
    <hr>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockDelimiter',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
